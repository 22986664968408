.sideBar {
  height: 64px;
  display: flex;
  justify-content: center;
  width: 100%;
  border-right: 0.1px solid rgb(214, 214, 214);
  margin-bottom: 32px;
}

.sideBar h2 {
  text-transform: capitalize;
  color: #2a3b6b;
}

.links {
  display: flex;
  width: 100%;
  justify-content: left;
  padding-top: 32px;
  padding-left: 32px;
}

.links a {
  color: #2a3b6b;
  text-align: left;
}

.links a:first-child {
  border-radius: 3px 0 0 3px;
}

.links a:last-child {
  border-radius: 0 3px 3px 0;
}

.gptTptDiv {
  background-color: #FFB3D7;
  width: 300px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  transition: width 1s;
}

.gptTptBtn {
  background-color: #C33273;
  border: none;
  border-radius: 5px;
  width: 85%;
  color: white;
}

.openGptTptBtn {
  background-color: #FFB3D7;
  border: none;
  padding-top: 5px;
  padding-left: 20px;
}

.closeGptTptBtn {
  background-color: #FFB3D7;
  border: none;
}

.gptTptContentSmall {
  margin-left: 5px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #8E2554;
}

.gptTptContentSmall:visited {
  color: #8E2554;
}

.gptTptContentBig {
  margin-top: 10px!important;
  margin-left: 25px;
  margin-right: 25px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #8E2554;
}

.gptTptLink {
  color: white!important;
  width: 100%;
  display: block;
  text-align: center!important;
}

.gptTptLink:visited {
  color: white!important;
}

#closeGptTptDiv {
  border-radius: 2px;
  /*banner closed by default*/
  height: 0px;
  z-Index: 1;
  flex-direction: column;
  overflow: hidden;
  margin-left: -300px;
}

#openGptTptDiv {
  overflow: hidden;
}

.basicSide {
  width: 16%;
  height: 64px;
  display: flex;
  justify-content: center;

  align-items: center;
  border-bottom: 5px solid transparent;
  transition: width 1s;
}

.basicSide:hover {
  cursor: pointer;
  /* border-bottom: 5px solid #003972; */
}

.sideBottom {
  display: flex;
  flex-direction: column;
  height: 33%;
  justify-content: flex-end;
}

.lastIcon:hover {
  background-color: #d1edfb;
  cursor: pointer;
}

.lastIcon {
  margin-bottom: 2.4em;

  visibility: hidden;
}

.nav-tile {
  color: black;
  font-family: Lato;
  font-size: 20px;
  font-weight: 300;
}

.nav-tile:focus {
  outline: none;
}

@media screen and (max-width: 991.98px) {
  .basicSide {
    width: 50%;
  }
}
