#languageHeader {
    width: 120px;
    border: 1px solid #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 120px;
    border-right: 0;
}

#originalNameHeader {
    width: 120px;
    border: 1px solid #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 120px;
    border-right: 0;
}

#customizedNameHeader {
    width: 120px;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    height: 32px;
    flex-grow: 1;
    padding-left: 11px;
}

.languageHeaderRow {
    display: flex;
    flex-direction: row;
    background-color: #fafafa;
}

.languageRow {
    display: flex;
    flex-direction: row;
}

.languageCell {
    width: 120px;
    border: 1px solid #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 120px;
    border-right: 0;
}