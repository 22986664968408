.urlInputField {
    outline: 0;
    width: 30em;
    height: 40px;
    border: solid 1px #dadee1;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    padding: 0 1em;
    vertical-align: middle;
    font-size: 16px;

    user-select: text !important;
}
.urlInputActions{
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
}

.urlInputFieldContainer{
    margin-right: 1em;
    position: relative;
}

.urlInputSpinner{
    width: 2em;
    height: 40px;
}

.websitetranslatorContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1.5em;
    width: 100%;
    padding: 32px;
}

.websiteTranslatorInputsContainer{
    margin-bottom: 2em;
    display: flex;
}

.previousWebsiteTranslateRequests{
    padding-left: 1em;
    text-align: left;
}

.previousWebsiteTranslateRequests ul {
    margin-top: 0;
}

.previousWebsiteTranslateRequest{
    cursor: pointer;
    text-decoration: underline;
    padding-left: .5em;
    margin-left: 2em;
}

.websiteTranslationerrorMessage{
    margin-bottom: 2em;
    color: #BB0000;
    font-size: 1em;
}

.langError{
    border: 2px solid #ff0000;
}

.websitetranslator-language-selection{
    display: flex;
    justify-content: space-between;
    width: 50%;
    align-items: center;
    margin-bottom: 2em;
}

.wtLoadOverlay{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
}

.lastRequestLoader{
    margin-bottom: 2em;
    font-size: 1em;
    text-align: left;
}

.lastRequestLoader button {
    margin-left: 1em;
}