.langSelection{
    width: 180px;
    font-size: 16px;
    height: 40px;
    color: #333333;
    font-weight: bold;
}

.engineSelection{
    width: 237px;
    font-size: 16px;
    height: 40px;
    color: #333333;
    font-weight: bold;
}

.categorySelection {
    width: 237px;
    font-size: 16px;
    height: 40px;
    color: #333333;
    font-weight: bold;
}

.triggerIcon{
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    border-bottom: 2px solid black;
    border-right: 2px solid black;
    margin-right: 8px;
}

.error{
    border-color: red !important;
}