.c-modal-cover{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-modal{
  width: 862px;
  height: 90%;
  margin: auto;
  background-color: #ffffff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: absolute;
  top:10%;
  bottom: 10%;
  transition: left .5s, right .5s, top .5s, bottom .5s, width .5s;
  border-radius: 3px;
  font-weight: bold;
  font-size: 16px;
  color: #595959;
}

.c-modal__body{
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}

.upload-area{
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  height: 0;
}

.fileList{
  overflow: auto;
  flex: 1 0 0;
  flex-basis: 0;
  height: 0;
  padding-left: 36px;
  padding-right: 16px;
  box-shadow:
  inset 0 -3em 3em rgba(0,0,0,0.1),
  0 0  0 2px rgb(255,255,255);
  margin-left: 2px;
  margin-right: 2px;
  color: black;
  font-weight: normal;
}

.addFilesbtnContainer{
  display: flex;
  justify-content: center;
  flex: 0 0 0;
  margin-left: auto;
}

.categoryAndSubmissionNameWrapper {
  display: flex;
  flex: 0 0 0;
  padding-top: 24px;
}

.deleteFileBtn {
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-right: 24px;
}


#c-modal__swap-btn{
  width: auto;
  margin: 0 0.5em;
}

.translationOptions{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 24px 24px 0 24px;
}

.spinDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.upload-message-text{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.submissionNameTitle {
  margin-bottom: 4px;
  height: 20px;
}

.spinDiv > div{
  flex-shrink: 0;
  margin: 0;
}

.standardBtn{
  flex-shrink: 0;
}

.modal-footer{
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px 24px;
}

.language-selection{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.language-selection p {
  margin: 0!important;
  padding-bottom: 8px;
}

.footerContentWrapper{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-top: 24px;
}

.c-modal__close{
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 0;
  line-height: 1;
  border: 0;
  box-shadow: 0;
  cursor: pointer;
  background-color: #9FA8BC;
}

.c-modal__close-icon{
  width: 25px;
  height: 25px;
  fill: transparent;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 3;
}

.c-modal-description{
  text-align: center;
  font-size: 16px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.c-modal__header{
  background-color: #a0a8bd;
  color: black;
  height: 64px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-modal__header h1{
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
}


.u-hide-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  /*margin: -1px !important;*/
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.errorArea{
  color: #ff0000;
  height: 20px;
  font-weight: normal;
}

.warningArea{
  color: #0E23E4;
  height: 20px;
  font-weight: normal;
}

.ocrFlagContainer{
  display: flex;
  font-size: 16px;
  font-weight: 400;
  height: 20px;
  margin-bottom: 16px;
  color: #333333;
}

.ocrFlag:focus ~ .ocrMark{
  box-shadow: 0 0 5px 0px #000;
}

.ocrFlag{
  position: absolute;
  left: -100vw;
}

.ocrMark {
    width: 1.5em;
    height: 1.5em;
    border-radius: 3px;
    border: 2px solid #000;
    margin-left: 1em;
}

.ocrMark * {
  opacity: 0;
}

.ocrFlag:checked ~ .ocrMark * {
  opacity: 1;
}


.ocrHelp {
  padding-left: 10px;
  color: #003162;
  font-weight: normal;
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
}

.ocrHelp:hover {
  color: #001E3C;
  font-weight: bold;
}

.errorBox{
  border-color: red !important;
}

#addFilesBtn {
  /*margin: 0 0 0 .5em !important;*/
  margin-right: 16px;
}

#submissionConcept {
  width: 237px;
  height: 40px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  font-weight: bold;
  color: #595959;
}

#fileCategory {
  width: 180px;
  margin-bottom: 15px;
  height: 40px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  text-align: center;
}

#submissionConcept:focus {
  outline: none;
  border: 1px solid #012169;
  border-radius: 3px;
  box-shadow: 0 0 0 1px #012169;
}

.fileCategoryWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px 24px;
}

.fileCategoryWrapper p{
  margin: 0 0 4px 0;
  height: 20px;
}

.submissionNameWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px 0;
}

.submissionNameWrapper p{
  margin: 0 0 4px 0;
  height: 20px;
}

@media screen and (max-height: 600px){
  .c-modal{
    top: 0;
    bottom: 0;
  }
  .translationOptions{
    flex-direction: row;
  }
}

@media screen and (max-height: 400px){
  .c-modal-description{
    display: none;
  }

  .c-modal__header{
    height: 2em;
  }

  .c-modal__header > h1{
    font-size: 1.5em;
  }
}

@media screen and (max-width: 1400px){
  .c-modal{
    width: 60%;
  }
}

@media screen and (max-width: 1000px){
  .c-modal{
    width: 80%;
  }
}

@media screen and (max-width: 700px){
  .c-modal{
    width: 100%;
  }
}