.h {
  margin: 0;
  font-family: "Lato";
  font-weight: 500;
  font-size: 22px;
  vertical-align: middle;
  height: 2.5em;
}

.document-dropzone-text {
  font-size: 21px;
  font-family: Lato;
  margin: 0;
  position: relative;
  color: #4E6273;
}

.document-dropzone-file-message {
  position: relative;
  top: -10%;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.documentBox {
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 4px;
  height: 267px;
  background-color: #ffffff;
  overflow-Y: hidden;
  scrollbar-width: thin;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.documentBoxWrapper {
  width: 100%;
  border-bottom: 1px solid #dfe6e9;
  padding: 32px;
}

.documentTableBody {
  background-color: transparent;
}

.table-outer-container {
  min-height: 35vh;
  width: 100%;
  padding: 32px;
}
.table-outer-container-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  width: 85%;
}


.download-source-btn {
  border: none;
  background-color: transparent;
}
/*ant-dropdown-menu-item ant-dropdown-menu-item-only-child*/
.ant-dropdown-menu-item {
  padding: 0px!important;
}

.ant-dropdown-menu {
  padding: 0px !important;
}

.translation-successful-text {
  padding-left: 8px;
}

.ant-popover-arrow {
  display: none!important;
}

.ant-popover-inner {
  box-shadow: none!important;
}

.ant-popover-inner-content {
  display: flex;
  padding: 0px!important;
}

.svgStyledOnHoverSubmissionName {
  padding: 8px;
  height: 40px;
  width: 40px;
}

.svgStyledOnHoverSubmissionName:hover {
  background: #D9D9D9;
  border-radius: 25px;
  padding: 8px;
}

.svgStyledOnHoverSubmissionNameDisabled {
  padding: 8px;
  height: 40px;
  width: 40px;
  cursor: default;
}

.svgStyledOnHover:focus {
  outline: transparent!important;
}

.ant-btn:not([disabled]):active {
  outline: transparent;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  padding-top: 9px!important;
}
  /*customized style for dropdown*/

.downloadZip {
  display: flex;
  vertical-align: middle!important;
  margin-top: -11px;
}

.downloadZip > div > button {
  height: 100%;
  border: none;
  padding-right: 0;
  --antd-wave-shadow-color: transparent!important;
  background-color: transparent!important;
  box-shadow: none;
}

.downloadZip > div > button:active {
  background-color: transparent!important;
  border-color: transparent!important;
}

.downloadSource > div > button:active {
  background-color: transparent!important;
  border-color: transparent!important;
}

.downloadSource > div > .ant-btn-default:hover, .downloadZip > div > .ant-btn-default:hover {
  color: transparent !important;
  border-color: transparent !important;
}

.downloadSource > div > .ant-btn-default:hover:focus, .downloadZip > div > .ant-btn-default:focus  {
  color: transparent !important;
  border-color: transparent !important;
}

.downloadSource > div > .ant-btn-default:hover:enabled, .downloadZip > div > .ant-btn-default:hover:enabled {
  color: transparent !important;
  border-color: transparent !important;
}

.ant-dropdown-trigger {
  margin: auto;
}

.coffeeButton:hover svg {
  transform: none;
}

.downloadZip > div > button {
  height: 40px;
}

.ant-collapse {
  background-color: #F0F0F0!important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  height: 48px!important;
}

.downloadZip {
  margin-right: -119.5%;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: none!important;
}

.ant-collapse:last-child{ /*this will select last tr*/
  border-bottom: 1px solid #d9d9d9;
}

@media screen and (max-height: 900px) {
  .document-dropzone-file-message {
    padding-top: 1rem;
  }
}

@media screen and (max-width: 1100px) {
  .table-outer-container {
    width: 100% !important;
  }
}

@media screen and (min-width: 1600px) {
  .downloadZip {
    margin-right: -120.5%;
  }
}
