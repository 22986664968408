.quickLinksContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.quickLinksContainer * {
  color: inherit;
}

.quickLinks {
  width: 85%;
}

.quickLinksLabel {
  font-style: italic;
}

.quickLinksList {
  display: inline;
  margin-left: 1em;
}

.quickLinksList li {
  display: inline;
  padding: 0 1em;
  text-decoration: underline;
}

.quickLinksList li a:hover {
  color: inherit;
}

.quickLinks a:visited {
  color: unset;
}
