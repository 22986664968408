.boxContainer {
  width: 55%;
  border-bottom: 1px solid rgb(223, 230, 233);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 4px;
  border: none;
  overflow-y: hidden;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-direction: column;
  color: #fff;
  margin: 2em auto 2em;
}

.termsHeader > img {
  height: 50px;
  max-width: 270px;
  margin-right: 0.5em;
}

.termsHeader {
  font-size: 2rem;
  margin: 0.5em 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.termsBody {
  padding: 2em;
  background-color: #fff;
  color: #000;
  font-size: 16px;
}

.termsBody > .standardBtn {
  margin-top: 2em;
}

.termsTitle {
  font-size: 24px;
  margin-bottom: 1em;
}

.termsContent {
  text-align: left;
}

.termsContent ul {
  margin-left: 2em;
}

.termsContent a {
  font-weight: bold;
}

.termsContent a:hover {
  text-decoration: underline;
}

.termsFooter {
  margin: 0.5em 0;
}

.overLay {
  position: fixed;
  z-index: 100000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ededed;
  overflow: auto;
}
