.clear-text-icon {
  display: inline-block !important;
  position: absolute;
  bottom: 4px;
  left: calc(50% - 3.9em);
}

.buttonShadeInside {
  border-bottom: 1.5px solid white;
  border-left: 1.5px solid white;
  border-top: 1.5px solid lightgray;
  border-right: 1.5px solid lightgray;
  position: relative;
  width: 4.6rem;
  height: 4.6rem;
  top: 20.5rem;
  right: 105rem;
  border-radius: 60%;
  background-color: #fff;
}

.langError {
  border: 2px solid #ff0000 !important;
}

.buttonShade {
  border-bottom: 0.5px solid white;
  border-left: 0.5px solid white;
  border-top: 1.5px solid lightgray;
  border-right: 1.5px solid lightgray;
  position: relative;
  width: 70px;
  height: 70px;

  border-radius: 60%;
  background-color: #fff;

  bottom: 5.7rem;
  left: -0.6rem;
}

.outside {
  background-color: white;
  box-shadow: 0 3px 4px 0 rgba(155, 155, 155, 0.28);
  width: 85%;
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  margin-top: calc(2.5em + 64px);
  align-items: center;
  padding: 1em;
  height: 100%;
}

.languagesInputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 32px;
}

.source {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 32%;
}

.textareaWrapper {
  height: 0;
  width: 100%;
  flex-grow: 1;
  flex-basis: 0%;
  padding-left: 32px;
  padding-right: 32px;
}

.textArea {
  display: flex;
  justify-content: space-between;
  max-height: calc(100% - 84px);

  min-height: 315px;
}

textarea {
  outline: 0;
  flex-shrink: 0;
  padding: 1em 2em 2.3em;
}

.label {
  font-size: 14px;
  font-weight: 600;
  padding: 0.5em 0;
}

.sourceOr {
  width: 100%;
  display: flex;
  align-items: center;
}

.targetLang-dropdown {
  display: flex;
  position: relative;
  width: 32%;
  justify-content: flex-end;
}

.or {
  width: 33%;
  display: flex;
  justify-content: flex-start;
  margin-left: 1.5em;
  align-items: center;
}

.dropdownTitle {
  text-align: left;
  color: #767676;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.flex-container-buttons-wrapper {
  display: flex;
  margin-top: 32px;
  justify-content: flex-start;
}

.flex-container-buttons-left {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: flex-start;
  margin-top: -24px;
}

.flex-container-buttons-right {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: flex-end;
}

.engine {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 64%;
  margin-bottom: 1.5em;
}

.format-alert-text-component {
  color: red;
  font-weight: 400;
  width: 95%;
  font-size: 1.5em;
  display: flex;
  align-items: center;
}

.copy-message-outside-container {
  display: flex;
  justify-content: center;
}

.copy-message-container {
  color: white;
  background-color: #434444;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 3px;
  padding: 0 1em;
}

.selectClass {
  transition: width 1s;
  font-size: 16px !important;
}

.targetText,
.sourceText {
  width: 50%;
  border: solid 1px #dadee1;
  border-radius: 3px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  flex-grow: 1;
  justify-content: space-between;
  overflow-y: auto;
}

.targetTransliteration {
  padding: 1em 2em 1.5em;
  text-align: left;
  font-style: italic;
  color: #434444;
  /*border-top: 1px solid #434444;*/
}
.sourceTextActionBtns {
  display: flex;
  justify-content: flex-end;
}

.targetTextActionBtns {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.targetTextActionBtn {
  border: none;
  width: 40px;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 3px;
  padding: 0;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.targetTextActionBtn > svg {
  width: 26px;
  height: 26px;
}

.uploadingWAVArea {
  position: absolute;
  bottom: -9em;
  /* width: 50%; */
  height: 3em;
  padding: 0 0.5em;
  /* background: white; */
  /* border-radius: 3px; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  grid-gap: 1em;
  gap: 1em;
}

.highlightForDnD {
  border: 2px solid blue;
}

/*engine dropdown class*/
.css-1uccc91-singleValue {
  max-width: 80%!important;
}

/*engine dropdown class, properties applied to each child (engines list)*/
.css-1237pv8 > * {
  height: max-content!important;
}

.copyTextIcon {
  margin-right: 1.5em!important;
  padding: 0;
  position: absolute;
  bottom: 8px;
  right: 1px;
}

.infoTextIcon {
  padding: 0;
  position: absolute;
  bottom: 8px;
}

.faceIcon {
  margin-left: 36px!important;
  position: absolute;
  bottom: 8px;
}

.translationQualityScoreText {
  height: 36px;
  margin-left: 75px;
  font-size: 16px;
  font-weight: bold;
}

.translationQualityScoreGood {
  color: #36A93F;
}

.translationQualityScoreNeutral {
  color: #F6610C;
}

.translationQualityScoreBad {
  color: #E4100E;
}

.copyTransactionRecordIcon {
  margin-right: 0.5em;
  padding: 0;
  position: absolute;
  bottom: 4px;
  right: 45px;
}

.infoQualityControlBtn {
  text-decoration: underline;
  color: white;
  background-color: #333;
  border: none;
  position:absolute;
  bottom: 0;
  right:0;
  font-size: 14px;
}

.swapButton {
  display: flex;
  cursor: pointer;
  width: 5.7em;
  align-items: center;
  justify-content: center;
  height: 3.7em;
}
 .qualityControlModal > .ant-modal-content {
  height: 503px;
}

 .qualityControlTable, .qualityControlTable > tr > th {
   border: 1px solid #E8E8E8;
   border-collapse: collapse;
 }

.qualityControlModalTableLeft {
  width: 238px;
  height: 56px;
  background-color: #F4F4F4;
}

.qualityControlModalTableLeft > p {
  padding-left: 16px;
  padding-top: 8px;
  vertical-align: middle;
  display: inline-block;
}

.qualityControlModalTableRight {
  width: 544px;
  background-color: #F4F4F4;
}

.qualityControlModalTableRight > p {
  padding-left: 16px;
}

.qualityControlModalTableLeftText {
  padding-left: 16px;
  vertical-align: super;
  color: black;
}

.qualityControlModalCloseBtn {
  float: right;
  margin-top: 30px;
  width: 130px;
  height: 40px!important;
  background: #003162!important;
  border-radius: 3px!important;
}

@media screen and (max-width: 1300px) {
  .selectClass {
    width: 60% !important;
  }
}

@media screen and (max-width: 992px) {
  .selectClass {
    width: 100% !important;
  }
  .format-alert-text-component {
    margin-top: 0 !important;
    font-size: 1.1em !important;
  }
  .outside {
    height: 85vh;
  }

  .copy-message-container {
    border-radius: 3px;
  }
}

@media screen and (max-height: 800px) and (orientation: landscape) {
  .format-alert-text-component {
    margin-top: 0 !important;
    font-size: 1.1em !important;
  }
  .outside {
    height: 85vh;
  }
}

@media screen and (max-height: 900px) and (orientation: landscape) {
  .outside {
    height: 85vh;
  }
}
