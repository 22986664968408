.userManagementHome {
  border-radius: 3px;
  box-shadow: rgb(0 0 0 / 50%) 0px 2px 4px;
  border: none;
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 6em;
  left: 2em;
  right: 2em;
  bottom: 2em;
  overflow: auto;
}

.userManagementContainer{
  text-align: initial;
}

.headerWithActionButtons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.userManagementRow {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.userManagementRow .tableContainer,
.userManagementRow .ant-table-wrapper,
.userManagementRow .ant-spin-nested-loading,
.userManagementRow .ant-spin-container,
.userManagementRow .ant-table,
.userManagementRow .ant-table-container,
.userManagementRow .ant-table-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0px;
  min-height: 0;
}

.userManagementRow .ant-table-body {
  overflow: auto !important;
}

.editOrgWrapper {
  width: 100%;
  padding: 0 2em 2em;
}

.editOrgContainer {
  border-radius: 3px;
  box-shadow: rgb(0 0 0 / 50%) 0px 2px 4px;
  border: none;
  background-color: rgb(255, 255, 255);
  width: 100%;
  padding: 0 1em 1em;
}
.filterTag {
  width: 100%;
  padding: 0 1em;
  margin-bottom: 1em;
  display: flex;
}

#showQuotasSwitch {
  margin-left: 5px;
  margin-bottom: 5px;
}

#quotaHidingMenu {
  margin-left: 10%;
}

.quotaFormWrapper {
  display: flex;
  flex-direction: row;
  height: 55px;
}

.engineDropdownFormWrapper {
  display: flex;
  flex-direction: row;
  height: 55px;
  width: 30%;
}

.quotaFormWrapperNoHeight, .flexRow {
  display: flex;
  flex-direction: row;
}

#quotaWordField  {
  margin-left: 19px;
  margin-right: 0;
  max-width: 32%;
}

#quotaPagesField {
  margin-left: 38px;
  margin-right: 0;
  max-width: 42%;
}

#quotaMinutesField {
  margin-left: 16%;
  margin-right: 10px;
  max-width: 42%;
}

#quotaCharField {
  margin-left: 29px;
  margin-right: 10px;
  max-width: 26%;
}

.report-date-selection {
  display: flex;
  margin-bottom: 1em;
  align-items: center;
}

.expirationDate {
  max-width: 65%;
  text-align: center;
  margin-left: 20px;
  border: 1px solid #d9d9d9;
  padding: 7px;
}

.switchButton {
  margin-left: 20px;
  margin-bottom: 5px;
}

.emailSupportWrapper {
  display: flex;
  height: 7%;
}

.emailSupportWrapper > .ant-form-item {
  margin-bottom: 0px;
}

.emailSupportWrapper > div {
  width: 100%;
}

.emailSupportWrapper > div > .ant-form-item-control {
  width: 95%;
}

.quotaFormWrapperWithSwitch > .ant-row > .ant-form-item-label {
  width: 200px;
}

.quotaFormWrapperWithSwitchSmall > .ant-row > .ant-form-item-label{
  width: 105px;
}

.quotaFormWrapperWithSwitchLongText > .ant-row > .ant-form-item-label{
  width: 170px;
  height: 66px;
}

.quotaFormWrapperWithSwitchLongText > .ant-form-item {
  margin-bottom: 0px;
}

.download-report-status {
  width: 250%;
  display: flex;
  position: absolute;
  z-index: 2;
  justify-content: space-around;
}

.download-report-in-progress {
  padding: 8px 20px;
  color: white;
  background: black;
  border-radius: 8px
}

.download-report-failed {
  padding: 8px 20px;
  color: white;
  background: #EC625C;
  border-radius: 8px
}


/*.controls-container {*/
/*  --highlight-width: auto;*/
/*  --highlight-x-pos: 0;*/

/*  display: flex;*/
/*}*/

.controls {
  display: inline-flex;
  justify-content: space-between;
  background: #F5F5F5;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  width: 50%;
  padding: 0px;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.controls input {
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.segment {
  min-width: 25%;
  position: relative;
  text-align: center;
  z-index: 1;
}

.segment label {
  cursor: pointer;
  display: block;
  font-weight: 700;
  padding: 4px;
  position: relative;
  transition: color 0.5s ease;
}

.segment.active label {
  color: #fff;
  background-color: #48A4FD;
}

.controls::before {
  content: '';
  background: #5465FF;
  border-radius: 8px;
  /*width: var(--highlight-width);*/
  /*transform: translateX(var(--highlight-x-pos));*/
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 0;
  z-index: 0;
  transition: transform 0.3s ease, width 0.3s ease;
}

.react-datepicker-popper {
  z-index: 2!important;
}

/* Hiding arrows from input type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}