.dw-button {
  /*test*/
  width: 100% !important;
  height: 100% !important;
}

.text-wrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.errorMessageDocumentTranslation {
  font-weight: bold;
}

.mailToLink {
  color: #1C335F;
  text-decoration: underline;
}
.mailToLink:hover {
  color: #1C335F;
  text-decoration: underline;
}

.subChild {
  height: 56px;
  display: flex;
  text-align: left;
  border-bottom: 1px solid #F0F0F0;
  line-height: 21px;
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.buttonRound {
  border: none;
  height: 3.5em;
  width: 3.5em;
  padding: 0;
  border-radius: 50%;
}

.buttonRound:hover {
  border: 1px solid grey;
}
.buttonRound:active {
  border: 2px solid grey;
}

/* .download {
  background-color: rgba(0, 92, 158, 0.35);
  border-radius: 3px;
  width: 100% !important;
  max-width: 90px !important ;

  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.download:hover {
  background-color: rgba(0, 92, 158, 0.6);
} */

.downloadSource:disabled  {
  background-color: #c4c4c4 !important;
}

.downloadSource, .downloadResult {
  width: 60%!important;
}

.deleteTd {
  width: 8.35%;
  padding: 8px 16px;
}

/* @media screen and (max-width: 1100px) {
  .statusTd {
    width: 18.5% !important;
  }

  .deleteTd {
    width: 10% !important;
  }

  .download {
    width: 10% !important;
  }
}

@media screen and (max-width: 768px) {
  .deleteTd {
    width: 10% !important;
  }
} */
.centered-text-cell {
  height: 100%;
  display: flex;
  vertical-align: middle!important;
  align-items: center;
  /*padding: 8px 0px 8px 0px;*/
}

.right-text-cell {
  height: 100%;
  display: flex;
  vertical-align: middle!important;
  align-items: center;
  /*padding: 8px 0px 8px 0px;*/
  place-content: flex-end;
}

a:visited {
  color: #4183c4;
}

#wordAndCharCountRow {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-bottom: 1em;
  background-color: white;
  min-height: 5.5vh;
}

.wordAndCharCountLink {
  width: 180px;
  height: 40px;
  display: inline-block;
  line-height: 40px;
}
#fileIconWrapper {
  width: 26px;
  padding-right: 1em;
  flex-grow: 0;
  flex-shrink: 0;
}

.anticon {
  color: rgb(50, 50, 50)!important;
}

.download-source-btn {
  border: none;
  background-color: transparent;
}

.extra-actions-btn, .extra-actions-btn-disabled {
  height: 48px;
  padding: 12px 20px 12px 20px;
  font-size: 14px;
}

.extra-actions-btn-disabled span {
  color: #c4c4c4;
}

.extra-actions-btn svg, .edit-file-btn svg {
  fill: #333333;
}

.edit-file-btn-disabled svg, .extra-actions-btn-disabled svg {
  fill: #c4c4c4;
}

.extra-actions-btn:hover svg {
  fill: white;
}

/*ant-dropdown-menu-item ant-dropdown-menu-item-only-child*/
.ant-dropdown-menu-item {
  padding: 0px!important;
}

.ant-dropdown-menu {
  padding: 0px !important;
}

.translation-successful-text {
  padding-left: 8px;
}

.ant-popover-arrow {
  display: none!important;
}

.ant-popover-inner {
  box-shadow: none!important;
}

.ant-popover-inner-content {
  display: flex;
  padding: 0px!important;
}

.ant-popover-placement-leftBottom {
  left:-webkit-calc(100% - 311px)!important;
  left:-moz-calc(100% - 311px)!important;
  left:calc(100% - 311px)!important;
  z-index: 1!important;
}

.requestHumanTranslationBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.boxWithArrow {
  position: relative;
}

/*box error with arrow pointing up*/
.boxWithArrow:before{
  content: "";
  position: absolute;
  top: -54%;
  left: 72.5%;
  z-index: 1;
  border: solid 15px transparent;
  border-right-color: rgb(246, 246, 246);
  transform: rotate(+90deg);
  -webkit-transform: rotate(+90deg);
}

.boxWithArrowLeft {
  position: relative;
  padding-left: 16px;
}

/*box error with arrow pointing up*/
.boxWithArrowLeft:before{
  content: "";
  position: absolute;
  top: -50%;
  left: 95.5%;
  z-index: 1;
  border: solid 15px transparent;
  border-right-color: rgb(246, 246, 246);
  transform: rotate(+90deg);
  -webkit-transform: rotate(+90deg);
}

.svgStyledOnHover {
  padding: 8px;
  height: 40px;
  width: 40px;
}

.svgStyledOnHoverSmall {
  height: 40px;
  width: 40px;
  padding: 12px 12px 0 12px;
}

.svgStyledOnHoverSmall:hover {
  background: #F0F0F0;
  border-radius: 25px;
  padding: 12px 12px 0 12px;
}

.svgStyledOnHover:hover {
  background: #F0F0F0;
  border-radius: 25px;
  padding: 8px;
}

.svgStyledOnHover:focus {
  outline: transparent!important;
}

/*this allows the name to be displayed on 3 lines max and then (...) if the text doesn't fit'*/
.fileName {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  line-height: 1.2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-btn:not([disabled]):active {
  outline: transparent;
}
/*customized style for dropdown*/
.downloadSource > div > button {
  height: 100%;
  border: none;
  padding-right: 0;
  padding-left: 8px;
  --antd-wave-shadow-color: transparent!important;
}

.ant-btn:focus {
  border-color: transparent!important;
}

.ant-btn:active {
  border-color: transparent!important;
}

.ant-btn:hover {
  border-color: transparent!important;
}

.ant-dropdown-trigger {
  margin: auto;
}

.coffeeButton:hover svg {
  transform: none;
}

@media screen and (max-width: 1500px) {
  .ant-popover-placement-leftBottom {
    left:-webkit-calc(100% - 280px)!important;
    left:-moz-calc(100% - 280px)!important;
    left:calc(100% - 280px)!important;
  }
}

@media screen and (min-width: 1500px) {
  .boxWithArrow:before{
    content: "";
    position: absolute;
    top: -56%;
    left: 70.5%;
    z-index: 1;
    border: solid 15px transparent;
    border-right-color: rgb(246, 246, 246);
    transform: rotate(+90deg);
    -webkit-transform: rotate(+90deg);
  }

  .boxWithArrowLeft:before{
    content: "";
    position: absolute;
    top: -50%;
    left: 96.2%;
    z-index: 1;
    border: solid 15px transparent;
    border-right-color: rgb(246, 246, 246);
    transform: rotate(+90deg);
    -webkit-transform: rotate(+90deg);
  }
}

/*@media screen and (min-width: 1400px) {*/
/*  .svgStyledOnHoverSubmissionName {*/
/*    margin-left: 6px;*/
/*  }*/
/*}*/

@media screen and (min-width: 1500px) {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 2%!important;
  }
  .downloadSource > div > button {
    padding-left: 2px;
  }
}


