.deleteModal {
  position: absolute;
  background-color: white;
  width: 35%;
  top: 50%;
  left: 50%;
  height: 30%;
  transition: all 0.2s;
  transform: translate(-50%, -50%);
  border-radius: 3px;
}

.closeDelete {
  left: calc(50% - 38px);
  position: relative;
  border: none;
  background-color: white;
  position: relative;
}

.confButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: calc(100% - 3em - 30px);
}
.confButtons p {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .deleteModal {
    width: 65%;
    height: 35%;
  }
}

@media screen and (max-height: 720px) {
  .deleteModal {
    height: 35%;
  }
}

@media screen and (max-height: 620px) {
  .deleteModal {
    height: 45%;
  }
}
