.header-button {
  font-weight: 500;
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  display: block;
  font-size: 16px;
  margin-bottom: 0;
  min-height: 40px;
  padding: 2px 20px 2px 0.5rem;
  position: relative;
  text-align: left;
}

.header-button-onClick {
  font-weight: 500;
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  display: block;
  font-size: 16px;
  margin-bottom: 0;
  min-height: 40px;
  padding: 2px 20px 2px 0.5rem;
  position: relative;
  text-align: left;
}
.header-button-onClick:before {
  content: '';
  width: calc(100% - 20px);
  height: 0px;
  left: 4px;
  position: absolute;
  bottom: 0px;
}

.header-button:focus {
  outline: 0;
  box-shadow: rgba(8, 132, 252, 0.3) 0px 0px 0px 3px;
  z-index: 1;
}

@media screen and (max-width: 780px) {
  .header-button {
    max-width: calc(100% - 20px);
  }
}
