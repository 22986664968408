body {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

textarea {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
}
textarea::placeholder {
  font-size: 16px;
}

input {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

button {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

label {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

#root{
  min-height: 100%;
  display: flex;
  flex-direction: column;
}