#providerHeader {
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 120px;
    border-right: 0;
    background-color: white;
}

#engineNameHeader {
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 120px;
    border-right: 0;
    background-color: white;
}

#customizedEngineHeader {
    width: 120px;
    display: flex;
    align-items: center;
    height: 32px;
    flex-grow: 1;
    padding-left: 11px;
    background-color: white;
}

.enginesTable {
    border-top: 1px solid #d9d9d9;
    padding: 0 8px;

}

.languageHeaderRow {
    display: flex;
    flex-direction: row;
    background-color: #fafafa;
}

.languageRow {
    display: flex;
    flex-direction: row;
    border: 1px solid #d9d9d9;

}

.languageCell {
    border: 1px solid #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 120px;
    border-right: 0;
}

.editEnginesColumn {
    padding-left: 0!important;
    padding-right: 0!important;
    text-align: center;
    border: none!important;
    border-bottom: 1px solid #d9d9d9!important;
    border-right: 1px solid #d9d9d9!important;
    border-left: 1px solid #d9d9d9!important;
}

.editEnginesColumnFirst {
    border-bottom: 1px solid #d9d9d9;
    text-align: center;
    border-left: 1px solid #d9d9d9;
}

.editEnginesColumnMiddle {
    padding-left: 0!important;
    padding-right: 0!important;
    text-align: center;
    border: none!important;
    border-bottom: 1px solid #d9d9d9!important;
    border-left: 1px solid #d9d9d9!important;
}

.editEnginesColumnEmpty {
    border-bottom: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
}

.engineSelector > .ant-select-selector {
    border: none!important;
    border-bottom: 1px solid #d9d9d9!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
}

.engineSelector > .ant-select-arrow {
    visibility: hidden;
}

#engine {
    background-color: transparent!important;
    border: none!important;
    color: black!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
    text-align: center;
}

.editEnginesColumnSelector {
    padding-left: 0px!important;
    padding-right: 0px!important;
}