.tableLanguages {
  /* width: -webkit-fill-available; */ /* For Chrome */
  width: calc(100% + 1px);
  flex-wrap: wrap;
  position: absolute;
  margin: -0.1em;
  align-content: space-between;
  height: 28em;
  display: flex;
  /* background-color: #dde3e7; */
  background-color: #fcfcfc;
  flex-direction: column;
  border: 0.5px solid rgb(223, 223, 223);
  z-index: 99999999999;
  /*   -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75); */
}

.tableLabelDiv {
  flex-direction: column;
  margin: 0.5em 0.5em;
  display: block;
}

.tableLabelDiv input {
  width: 0px;
  height: 0px !important;
}

.tableLabel {
  font-size: 15px;
  text-align: left;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
  padding: 0.5px;
}

.tableLabel:hover {
  background-color: #d8ecf9;
  color: #1959c9;
  padding: 0.5px;
}
