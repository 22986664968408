.sketch-picker {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: none !important;
}

.color-picker-move-up {
  bottom: 45px;
}

.colorPickerColor {
  width: 100%;
  height: 32px;
  border-radius: 2px;
}
.colorPickerSwatch {
  width: 100%;
  background: #fff;
  border-radius: 1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
}
.colorPickerPopover {
  position: absolute;
  z-index: 2;
}
.colorPickerCover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
