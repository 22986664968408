.modal-deletefile {
  border: none;
  background-color: white;
  height: 2.5em;
  width: 2.5em;
}

.modal-deletefile:hover {
  border: 1px solid grey;
  border-radius: 3px;
}

.coffeeButton {
  height: 40px;
  width: 40px;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 0.1em;
  overflow: hidden;
}
/* .coffeeButton:focus {
  outline: 2px dashed #17171d;
} */
.coffeeButton:hover svg {
  transform: scale(1.2);
}
.coffeeButton::-moz-focus-inner {
  border: 0;
}
.coffeeButton svg {
  outline: none;
  transition: transform 0.15s linear;
}
