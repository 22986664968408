.logout {
  background-color: white;
  box-shadow: 0 3px 4px 0 rgba(155, 155, 155, 0.28);
  width: 55%;
  display: flex;
  flex-direction: column;
  margin-top: 5.5em;
  padding: 1em;
  align-items: center;
  justify-content: center;
}

.logout button:focus {
  border: 2px solid blue !important;
}
