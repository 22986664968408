.App {
  text-align: center;
  display: flex;
  background-size: cover;
}

.AppContainer {
  height: 100%;
  display: flex;
  flex-direction: column;

  flex: 1 0 0;
  flex-basis: 100%;
}

a {
  text-decoration: none;
}

a:visited {
  color: initial;
}

input:focus,
select:focus,
textarea:focus,
img {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

button {
  height: 2.3em;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}

.greyBtn {
  background-color: #c4c4c4;
  color: black;
  border: none;
  width: 180px;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 3px;
}

.standardBtn {
  border: none;
  width: 180px;
  height: 40px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 3px;
  vertical-align: middle;
}

.closeModalBtn {
  margin: auto;
}

.standardBtn:disabled {
  background-color: #c4c4c4 !important;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
