ul {
  padding: 0;
}

.header-title-h1 {
  font-weight: 100;
  margin: 1.5rem !important;
  color: white;
}

.header-button-container {
  display: flex;
  justify-content: space-evenly;
  min-width: 32%;
}

.dropdown-svg {
  margin-left: 0.2rem;
  width: 12px;
  height: 12px;
}

.lang-user-flex-container {
  display: flex;
  align-items: center;
}

.header-lang {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  padding: 0.4em;
  border: 2px solid white;
  border-radius: 10rem;
  cursor: pointer;
}

.header-lang > p {
  margin: 0;
  padding: 0;
  line-height: 1rem;
  height: 1.2rem;
  text-align: center;
}

.header-lang-activate {
  padding: 0 !important;
  margin: 2rem;
  position: relative;
}

.headerH1Text {
  font-weight: 100;
  color: white;
  margin: 1.5rem;
}

header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerDiv {
  height: 64px;
  width: 100%;
  margin-top: 0px;
  padding: 0 2em 0 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: white;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.logoHeader {
  display: flex;
  align-items: center;
}

.imgHeader {
  height: 50px;
  max-width: 270px;
}

.imgMenu {
  color: white;
  width: 10px;
  margin-top: 3px;
}

.lang-menu {
  width: 5.5%;
  min-width: 50px;
}

.menu {
  background-color: white;
  min-width: 60px;
  font-weight: 600;
  border-radius: 5px;
  margin-top: 2em;
  margin-top: 2em;
  margin: 14px 0;
  list-style: none;
  white-space: nowrap;
  margin-top: 20px;
  color: black;
  font-weight: 600;
  background-color: white;
  box-shadow: 5px 5px 5px #888888;
  position: absolute;
  top: 30px;
  border: 1px solid lightgray;
}

.user-menu {
  right: 0;
  width: 5.5%;
}

#Menu > li,
#Menu > li > button {
  padding: 0;
  position: relative;
  font-size: 14px;
  text-align: center;
  padding: 0.3rem;
  cursor: pointer;
  font-family: Lato;
  font-weight: 500;
}

#Menu :hover {
  background-color: #dcecfc;
}

.header-button-container {
  flex-wrap: wrap;
}

#dropdownUserSetting-list {
  width: auto;
}

#dropdownUserSetting-list > li {
  cursor: pointer;
}

.headerMargin {
  width: 100%;
  height: 4.5em;
}

@media screen and (max-width: 991px) {
  .header-title-h1 {
    font-weight: 100;
    margin-right: 0 !important;
    color: white;
  }
  .header-button-container {
    margin: 0 !important;
    flex-wrap: nowrap !important;
    justify-content: flex-start;
    min-width: 250px;
  }
}
@media screen and (max-width: 780px) {
  .header-button-container {
    margin-left: 30px;
  }

  .lang-menu {
    right: 7rem;
  }
  .user-menu {
    right: 0.8rem;
  }

  .header-title-h1 {
    margin-left: 0 !important;
  }
  .header-button {
    max-width: 50px;
  }
}
